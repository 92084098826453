@font-face {
    font-family: "Post Grotesk Medium";
    src: url("b566a02e9be58d32ff59f5bfb710355a.eot");
    /* IE9*/
    src: url("b566a02e9be58d32ff59f5bfb710355a.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("b566a02e9be58d32ff59f5bfb710355a.woff2") format("woff2"),
        /* chrome、firefox */
        url("b566a02e9be58d32ff59f5bfb710355a.woff") format("woff"),
        /* chrome、firefox */
        url("b566a02e9be58d32ff59f5bfb710355a.ttf") format("truetype"),
        /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
        url("b566a02e9be58d32ff59f5bfb710355a.svg#Post Grotesk Medium") format("svg");
    /* iOS 4.1- */
}